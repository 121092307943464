<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
            <div class="MBtextcont">
                <p>
                    Вас ждет непростой, но очень важный урок. Прям проверка на… Надеюсь, что
                    в этом занятии вас ждет много открытий ))
                </p>
                <p>
                    Хочу сразу предупредить, я не профессионал, просто хочу лепить голову
                    куклы правильно ) Поэтому все мои методы в этом уроке чисто субъективны,
                    хотя и взяты из профессиональных источников.
                </p>
            </div>

            <VideoView video-id="52d479e4e04c4fd0bfab54bd56281d8a"/>

            <div class="MBtextcont">
                <p>
                    От чертежа, который вы построите вместе со мной, мы перейдем к практике
                    на пластилине. Это всегда чудо, когда из плоского изображения получается
                    объемный вполне реальный объект, да еще и такой симпатичный.
                </p>
                <p>
                    Итак, если у вас нет под рукой реального черепа, в смысле модели ) то
                    его изображение в разных ракурсах можно скачать и распечатать из
                    интернета. Так же эти изображения можно найти в любом пособии по
                    анатомии, лучше для художников или скульпторов.
                </p>
                <p class="nomargin">Помимо картинок с черепом нам понадобятся:</p>
                <ul>
                    <li>Пластилин скульптурный</li>
                    <li>Стеки для лепки, нож</li>
                    <li>Карандаш, линейка, резинка</li>
                    <li>Лист бумаги А4, в клетку или миллиметровка</li>
                    <li>Циркуль</li>
                    <li>Зубочистки или маленькие палочки (спички)</li>
                    <li>Фольга или основа для черепа</li>
                </ul>

                <h2>2-ой шаг. Обрубовка</h2>
                <p>
                    Начнем мы, как всегда, с изображения на бумаге, а затем перейдем к
                    пластилину. Ваша задача:
                </p>
                <ol>
                    <li>
                        Найти и распечатать любое подходящее вам изображение лица и поделить
                        его на зоны.
                    </li>
                    <li>
                        А затем постараться применить эти знания на голове из пластилина, как
                        это сделано в уроке.
                    </li>
                </ol>
                <p>
                    На этом можно остановиться. То, что я делаю почти готовое личико — это
                    просто лирическое отступление для вдохновения его делать не нужно.
                </p>
            </div>

            <VideoView video-id="dd0837c039524885bbab5f3dbed7def8"/>

            <div class="MBtextcont">
                <h2>3-ий шаг. Лепим лицо</h2>
                <p>
                    Сегодня мы лепим глаза, нос, рот и ухо. Вас опять ждет очень много
                    материала! По итогу этого занятия у вас в руках будет готовая
                    пластилиновая головка )
                </p>
                <p>
                    Пластилин материал не очень благодатный для лепки кукольных головок… Но
                    для экспериментов и набросков вполне подойдет.
                </p>
            </div>

            <VideoView video-id="dd6fb5a583d64a26bfe9890f05bf64d9"/>

            <div class="MBtextcont">
                <h2>4-ый шаг. Создаем основу из LaDoll</h2>
                <p>
                    Вот и настал день, когда мы с вами начинаем работу с пластиком. Ура!
                    Наконец-то начинается самое интересное.
                </p>
                <p>
                    Сегодня мы проанализируем все, что мы прошли и сделаем основу для
                    головы. От вас потребуется не просто повторить за мной весь урок, а
                    очень хорошо подумать именно над своим образом и особенностями строения
                    его головы и лица.
                </p>
            </div>

            <VideoView video-id="fc5021c01a234bf9bce5f94205f0b680"/>

            <div class="MBtextcont">
                <p class="nomargin">Нам понадобится:</p>
                <ul>
                    <li>Пластилин</li>
                    <li>Палочки для суши</li>
                    <li>Пищевая пленка</li>
                    <li>Стеки, нож или стек гладилка</li>
                    <li>Пластик La Doll</li>
                    <li>Глаза или шарики для глаз</li>
                    <li>Лист бумаги А4, карандаш, линейка</li>
                    <li>Циркуль</li>
                </ul>

                <h2>5-ый шаг. Создаем объемы</h2>
                <p class="nomargin">
                    Закрепляем все знания на пластике. Кому-то из вас будет трудно
                    переключиться с пластилина на пластик и привыкать к новому материалу, а
                    кто-то вздохнет с облегчением. Сегодня мы:
                </p>
                <ol>
                    <li>Лепим лицо и делаем обрубовку</li>
                    <li>Проверяем голову на симметрию и учимся видеть свои ошибки!</li>
                </ol>
            </div>

            <VideoView video-id="d78d3fecfa05416dad2402334b25c972"/>

            <div class="MBtextcont">
                <p>
                    Как всегда, этот материал потребует от вас много внимания и труда, но
                    все в этой жизни вознаграждается! Главный приз этого марафона - это ваш
                    опыт! Как бы пафосно это не звучало ))) Это то, что останется с вами и,
                    надеюсь, изменит ваше творчество в лучшую сторону. Мои куклы точно
                    изменятся уж не знаю, хорошо это или плохо…
                </p>

                <h2>6-ой шаг. Доработка деталей</h2>
                <p>
                    Вот ОНО! Последнее занятие марафона. Так сказать, венец нашего
                    плодотворного пути)
                </p>
            </div>

            <VideoView video-id="9ad44e9dd41b49e8a1e0ba2698b6bab5"/>
            <!--    <KLazyVideo video-id="9ad44e9dd41b49e8a1e0ba2698b6bab5"/>-->

            <div class="MBtextcont">
                <p>
                    Надеюсь, что ваша работа продвигается уже намного легче и быстрее, и
                    совсем скоро вы скажете себе и нам:
                </p>
                <p>
                    "Я это сделал(а)! Я сдержал(а) данное слово! Вот оно лицо моей новой
                    куклы! Троекратное УРА, УРА, УРА!"
                </p>
                <p>Здорово, правда? )</p>
            </div>

    </KCourse>
</template>

<script>
    import VideoView from "../Widgets/VideoView";
    import KCourse from "../Widgets/KCourse";
    // import KLazyVideo from "../Widgets/KLazyVideo";


    export default {
        name: "MBCourseHead",
        components: {
            // KLazyVideo,
            VideoView,
            KCourse,
        },
        data() {
        return {
        
            items: [
                { title: "Лепим череп", content: "" },
                { title: "Делаем обрубовку", content: "" },
                { title: "Лепим лицо", content: "" },
                { title: "Основа из La Doll", content: "" },
                { title: "Доработка деталей", content: "" },
            ],
        }
        }
    }
</script>

<style>

</style>